import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import ContactForm from "../../components/ContactForm";
import Marker from "../../components/Marker";
import Img from "gatsby-image";
import headoffice from "../../images/aussi.jpg";
import colombohub from "../../images/colombo.jpg";
import indiahub from "../../images/India New.jpg";
import UKhub from "../../images/UK-office.jpg";
import GoogleMapReact from "google-map-react";
import { graphql } from "gatsby";
import IntroBanner from "../../components/IntroBannerRevamp";
import Banner from "../../images/contact-banner.jpg";
import OfficeAddressContacts from "../../components/OfficeAddressContacts";
import ContactCard from "../../components/ContactCard";

const Contact = (props) => (
  <Layout bodyClass="page-contact">
    <Seo
      title="Contact Us"
      description="Have a question? We are eager to help! Please feel free to give us
              a call, send us an email, or leave us a message"
    />

    <IntroBanner
      title="info@itelasoft.com"
      subtitle="Let's start a conversation..."
      image={Banner}
    />

    <div className="content">
      <div className="container">
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <h1 className="mb-2">Contact Us</h1>
            <p>
              Have a question? We are eager to help! Please feel free to give us
              a call, send us an email, or leave us a message in the form below.
            </p>
          </div>
          <div className="col-md-6 pr-md-5">
            <div className="mt-5">
              <ContactForm />
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="mb-4">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="title-2">Head Office - Sydney</h4>
                  <p>
                    C/- Stone and Chalk
                    <br />
                    Level 4, 11 York Street
                    <br />
                    Sydney, NSW, 2000
                    <br />
                    Australia
                    <br />
                    1300 842 108
                    <br />
                    +61 288 964 301
                    <br />
                    info@itelasoft.com
                  </p>
                </div>

                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    alt="head office"
                    src={headoffice}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="title-2">Development Hub - Sri Lanka</h4>
                  <p>
                    No 69,
                    <br /> Old Kottawa Road,
                    <br />
                    Mirihana, <br />
                    Sri Lanka <br />
                    +94 112 099 900 <br />
                    +94 112 099 966
                  </p>
                </div>
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    alt="colombo hub"
                    src={colombohub}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="title-2">Development Hub - India</h4>
                  <p>
                    Plot Number D 235 (P),
                    <br />
                    First Floor Near Amazon Warehouse,
                    <br />
                    Phase 8B,
                    <br />
                    Industrial Area,
                    <br />
                    Mohali,
                    <br />
                    Sahibzada Ajit Singh Nagar,
                    <br />
                    Punjab,
                    <br />
                    India
                    <br />
                    160071
                    <br />
                    +91 700 920 9673
                  </p>
                </div>
                <div className="col-md-6">
                  <img className="img-fluid" alt="india hub" src={indiahub} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="office-contact-details">
            <div className="container">
              <div className="row">
                <div className="col-12">
                    <OfficeAddressContacts 
                      officeimage={headoffice}
                      officeName="Head Office - Sydney"
                      email="info@itelasoft.com"
                      phonenumber1="1300 842 108"
                      phonenumber2="+61 288 964 301"
                      addressLine1="C/- Stone and Chalk"
                      addressLine2="Level 4, 11 York Street"
                      addressLine3=" Sydney, NSW, 2000"
                      addressLine4="Australia"
                      />
                </div>
                <div className="col-12 col-lg-6">
                    <OfficeAddressContacts 
                      officeimage={colombohub}
                      officeName="Development Hub - Sri Lanka"
                      email=""
                      phonenumber1="+94 112 099 900"
                      phonenumber2="+94 112 099 966"
                      addressLine1="No 69,"
                      addressLine2="Old Kottawa Road,"
                      addressLine3="Mirihana,"
                      addressLine4="Sri Lanka"
                      />
                </div>
                <div className="col-12 col-lg-6">
                    <OfficeAddressContacts 
                      officeimage={indiahub}
                      officeName="Development Hub - India"
                      email=""
                      phonenumber1="160071"
                      phonenumber2="+91 700 920 9673"
                      addressLine1="Plot Number D 235 (P),"
                      addressLine2="First Floor Near Amazon Warehouse,"
                      addressLine3="Phase 8B, Industrial Area, Mohali,"
                      addressLine4="Sahibzada Ajit Singh Nagar, Punjab, India"
                      />
                </div>
              </div>
            </div>
      </div> */}
      <div className="office-contact-details mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <ContactCard
                image={headoffice}
                hubname="Head office"
                location="Sydney, Australia"
                email="info@itelasoft.com"
                phonenumber1="1300 842 108 / +61 288 964 301"
                address="C/- Stone and Chalk Level 4, 11 York Street, Sydney, NSW, 2000, Australia"
              />
            </div>
            <div className="col-12 col-lg-6">
              <ContactCard
                image={colombohub}
                hubname="Development hub"
                location="Sri Lanka "
                phonenumber1="+94 112 099 900 / +94 112 099 966"
                address="No 69, Old Kottawa Road, Mirihana, Sri Lanka"
              />
            </div>
            <div className="col-12 col-lg-6">
              <ContactCard
                image={indiahub}
                hubname="Development hub"
                location="India "
                phonenumber1="+91 700 920 9673"
                address="Plot Number D 235 (P), 
                    First Floor  Near Amazon Warehouse, 
                    Phase 8B, 
                    Industrial Area, 
                    Mohali, 
                    Sahibzada Ajit Singh Nagar, 
                    Punjab, 
                    India 
                    "
              />
            </div>
            <div className="col-12 col-lg-6">
              <ContactCard
                image={UKhub}
                hubname="Operations"
                location="EMEA"
                phonenumber1="+44 7920 077135"
                address="128 City Road, London,
                EC1V 2NX,
                United Kingdom"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="contact-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyB-0eKRjkpVuEku031RC1WP93RuxpoFXvw",
              }}
              defaultCenter={[-33.86495164836189, 151.2052056013851]}
              defaultZoom={15}
            >
              <Marker lat="-33.86495164836189" lng="151.2052056013851" />
            </GoogleMapReact>
            {/* <div className="mymap">
              {typeof window !== "undefined" && (
                <LeafletMapSEC
                  position={[1.2873123503388268, 103.84699577272488]} // Your Coordinates
                  zoom={16} // Zoom Level
                  markerText={"Local Cafe, 65 Park Row"} // Icon text
                />
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "contact-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Contact;
