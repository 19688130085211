import React from "react";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

const OfficeAddressContacts = (props) => {
  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-6">
          <div className="office-image">
              <img
                className="img-fluid"
                alt="head office"
                src={props.officeimage}
              />
          </div>
        </div>
        <div className="col-md-6">
          <div className="office-info-side">
            <h4 className="title-2">{props.officeName}</h4>
            <div className="contact-info-group">
              <div className="icon-side">
                  <FaEnvelope />
              </div>
              <div className="data-side">
                <label>Email:</label>
                <p>
                  {props.email}
                </p>
              </div>
            </div>
            <div className="contact-info-group">
              <div className="icon-side">
                  <FaPhoneAlt />
              </div>
              <div className="data-side">
                <label>Phone:</label>
                <p>
                  {props.phonenumber1}
                </p>
                <p>
                  {props.phonenumber2}
                </p>
              </div>
            </div>
            <div className="contact-info-group">
              <div className="icon-side">
                <FaMapMarkerAlt />
              </div>
              <div className="data-side">
                <label>Location:</label>
                <p>
                  {props.addressLine1}
                </p>
                <p>
                  {props.addressLine2}
                </p>
                <p>
                  {props.addressLine3}
                </p>
                <p>
                  {props.addressLine4}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeAddressContacts;
